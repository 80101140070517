@import '../styles/colors';

.left-nav{
    overflow: scroll;
    background-color: $lightgray;
    left: 0;
    top: 0;
    z-index: 1000;
    height: 100vh;
    color: $black;
    
    .left-nav-header{
        text-align: center;
        .close-btn, .back-btn{
            position: absolute;
            left: 2px;
            top: 2px;
            color: $black;
            padding: 10px;
            font-size: 1.5rem;
        }
        img{
            height: 75px;
        }
    }

    .pending-text {
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
    }

    .extra-links {
        border-top: 2px solid black;
        margin-top: 20px;
        padding-top: 20px;

        a:hover {
            text-decoration: none;
        }
    }

    .home-nav {
        a:hover {
            text-decoration: none;
        }
    }

    .search-suggestions{
        list-style: none;
        padding: 0;
        .item-name{
            text-transform: uppercase;
            color: $lightpurple;
            font-weight: bold;
            display: block;
            opacity: 60%;
        }
        .suggestion{
            margin: 10px 0;
            .btn{
                text-decoration: underline;
            }
        }
    }

    .nav-container, .search-results-container{
        .item-name{
            text-transform: uppercase;
            color: $black;
            font-weight: bold;
            display: block;
            opacity: .6 !important;
        }
        .submenu-group{
            margin: 20px 0;

            p {
                color: $black;
                opacity: .6 !important;
                font-size: 1.1rem;
                border-bottom: .5px solid darkgray;
                padding: 20px 0 !important;
            }
            .sub-nav-item{
                &:not(:last-child){
                    border-bottom: .5px solid darkgray;
                }
            }
        }
        .nav-item{
            padding: 10px 0px;

            &.root-nav-item{
                .nav-icon{
                    margin-right: 10px;
                    color: $darkgray;
                }
                .btn{
                    text-transform: uppercase;
                    font-weight: 500;
                    // font-size: 24px;
                    font-size: 1.1rem;
                }
            }

            &.sub-nav-item, &.search-result-item{
               
                .item-link{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0;
                    padding: 10px 0;
                    font-size: 1.2rem;
                    &.active{
                        color: $orange;
                    }
                    &:not(:last-child){
                        border-bottom: .5px solid $darkgray;
                    }

                    svg {
                        opacity: 60%;
                    }
                }
            }
        }
    }
}