@import '../styles/colors';

.hc-table{
    .table-controls-wrapper{
        margin-bottom: 5px;
        .custom-control-input:checked~.custom-control-label::before{
            background-color: $lightpurple;
            border: none;
        }
        .custom-control-label{
            color: $white;
        }
    }
    .table{
        background-color: $white;
        &.table-striped tbody tr:nth-of-type(odd){
            background-color: $lightgray;
        }

        td {
            a {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (min-width: 992px){
    .hc-table{
        margin-left: 20px;
        margin-right: 20px;
        .table{
            margin-left: auto;
            margin-right: auto;
            max-width: 900px;
            border-radius: 1.25rem;
            overflow: hidden;
            table-layout: fixed;
        }
    }
}