@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import './colors';
@import './buttons';
@import './text';

.w-95{
    width: 95% !important;
}