
.icon-btn, .transparent-btn{
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    vertical-align: baseline;
    color: $black;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
        background-color: transparent;
        border-color: transparent;
        color: $black;
    }

    &.icon-white{
        color: $white;
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            color: $white;
        }

    }
}