@import '../styles/colors';
.container-fluid.wrapper{
    padding-left: 0;
    padding-right: 0;
    height: 100%;
}

html, body, #root{
    height: 100%
  }

@media (max-width: 991.98px) {  
    .left-nav-wrapper{
        position: absolute !important;
        z-index: 1000;
        width: 90% !important;
        box-shadow: 10px 0px 15px rgba(0,0,0,0.3);
    }
}