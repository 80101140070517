@import '../../styles/colors';

.home{
    .home-menu{
        padding-top: 1rem;

        .menu-option{
            text-align: center;
            padding: 10px;
            .menu-option-icon{
                color: $orange;
            }
            .menu-option-title{
                font-size: 1.25rem;
                font-weight: 500;
                display: block;
                text-transform: uppercase;
            }
        }
    }

    .home-container {
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .navbar {
            margin: 0 -15px;
        }

        .home-footer {
            text-align: center;
            background-color: $gray;
            margin: 0 -15px;
            margin-top: auto !important;
            border-top: 2px solid #A292B4;

            img {
                max-width: 175px;
                margin: 20px auto;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .home {
        .home-menu {
            .menu-option {
                padding-top: 0;
                padding-bottom: 0;

                .menu-option-title {
                    padding: 0 .6rem;
                }
            }
        }
    }
}