.hc-card-deck{
    padding: 10px
}

.card.hc-card{
    border-radius: 1.25rem
}

.hc-carousel{
    .carousel-slider{
        height: 100%;

        .control-arrow {
            width: 13%;
            opacity: 0;
        }

        .slider-wrapper{
            height: 100%;
            .slider{
                height: 100%;
                .slide{
                    background: transparent;
                    height: 100%;
                }
            }
        }
    }
} 

.progress-container{
    margin-bottom: 20px;
    width: 56.25%;

    @media screen and (max-width: 992px) {
        width: 90%;
    }
    .progress{
        border: 1px solid white;
        border-radius: 15px;
        width: 90%;
    }
    span{
        color: white;
        text-align: center;
    }
}

.card-counter {
    font-size: .9rem;
    padding-left: .6rem;

    b {
        font-size: 1rem;
    }
}

.deck-column {
    .navbar-brand {
        word-wrap: break-word;
        max-width: 85%;
        text-align: center;
        white-space: normal;
    }
}
