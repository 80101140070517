@import '../styles/colors';

.form-control.search-input{
    background-color: transparent;
    border: 1px solid $darkpurple;
    border-radius: 25px;
    &:focus{
        box-shadow: none;
        border: 1px solid $darkpurple;
    }
    margin: 1.25rem auto;
    // &.active{
    //     background-color: $white;
    // }
}

.left-search {
    font-family: 'FontAwesome';
}