.hc-card{
    padding: 20px;
}
.card{
    overflow: scroll;
    &.image{
        img{
            max-height: 200px;
            max-width: 100%;
            width: auto !important;
        }
    }
}

.modal-content.image-card-modal{
    border-radius: 25px;
    overflow: hidden;
    .modal-body{
        padding: 0;
        text-align: center;
        .modal-image{
            width: 100%;
            display: block;
            margin: auto;
        }
    }
    .modal-footer{
        justify-content: center;
        white-space: break-spaces;
        max-width: none !important;
        padding: auto 20px;
    }
}

.form-welcome {
    display: block;
    text-align: center;
    margin: 1.25rem auto;
}

.modal-label {
    margin: 1.25rem auto;
    padding: 0 2rem;
    font-weight: bold;
}

.modal-select {
    border-radius: 1rem !important;
}

.modal-btn {
    margin: 1rem auto;
    border-radius: 20px !important;
    background-color: #855173 !important;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3) !important;
    padding: 0.625rem 2rem !important;
}

.modal-footer {
    border-top: none;
    max-width: 175px;
    margin: auto;

    img {
        max-width: 100%;
    }
}

.response-card-wrapper{
    position: relative;
    
    .response.card.back{
        position: absolute;
        justify-content: flex-start !important;
        top: 150%;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1s;
        box-shadow: 0px -5px 5px rgba(0,0,0,0.3);
        overflow: visible;

        .show-response-prompt{
            position: absolute;
            bottom: 102%;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 1;
            transition: all 1s;
            color: #4F4F50;
            font-size: 1.1rem;
            font-weight: 300;
            letter-spacing: 0;
            p{
                margin: 0;
            }
            svg {
                opacity: .75;
            }
        }
        .hide-response-prompt{
            transition: all 2s;
            opacity: 0;
            margin-bottom: 1rem;
            color: #4F4F50;
            font-size: 1.1rem;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1.25rem;
            
            p {
                margin-bottom: 0; 
            }

            svg {
                opacity: .75;
            }
        }
        &.preview{
            top: 90%;
            &.show{
                top: 0;
                box-shadow:none;
                .responses{
                    overflow: scroll;
                }
                .show-response-prompt{
                    opacity: 0;
                }
                .hide-response-prompt{
                    opacity: 1;
                }
            }
        }
    }

    .response-heading {
        border-radius: 4px;
        color: white;
        background-color: black;
        margin: auto;
        display: inline;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
    }

    .response-body {
        margin-top: 1rem;
    }
    
}

.welcome-link {
    text-decoration: underline;
}

.single-img-btn:focus {
    border: none !important;
    box-shadow: none !important;
}

.single-img {
    display: block !important;
    margin: 0 auto 1em !important;
    max-height: none !important;
    max-width: none !important;

}